import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { Form, Row, FormGroup, Col } from "reactstrap";
import { TextInputField } from "../../../../_shared/components/ReduxFormFields";

const propTypes = {
  searchThread: PropTypes.func,
};

const defaultProps = {
  isLoading: false,
};

const Search = ({ searchThread }) => {
  // eslint-disable-next-line no-unused-vars
  const [searchFunc, setSearchFunc] = useState({});

  const onChangeHandler = (e) => {
    const query = e.currentTarget.value;
    const params = {
      offset: 0,
      count: 10,
      query,
    };

    searchThread(params);

    const searchThreadDebounce = debounce(searchThread, 750, {
      maxWait: 1500,
    });

    setSearchFunc((prevSearch) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }

      return searchThreadDebounce;
    });

    if (query.length > 0) return searchThreadDebounce(params);

    searchThreadDebounce.cancel();
  };

  return (
    <Form className="search-bar">
      <Row>
        <Col>
          <FormGroup>
            <i className="fas fa-search" />
            <Field
              name="query"
              type="text"
              component={TextInputField}
              placeholder="Search the forum...."
              onChange={onChangeHandler}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  //   isLoading: state.ui.loading.trackMyOrders,
});

const formName = "search";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: TrackingValFunc,
})(Search);

export default connect(mapStateToProps, null)(connectedForm);

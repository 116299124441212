import React from "react";
import PropTypes from "prop-types";

import "./ProgressBar.scss";


const propTypes = {
  spinType: PropTypes.string,
};

const ProgressBar = ({ spinType }) => {
  return (
    <div className={`opn-progress-bar__spinner ${spinType}`}>
      <img src="/images/icons/opn-logo-no-bg.png" alt="opn logo" />
    </div>
  );
};

ProgressBar.propTypes = propTypes;

export default ProgressBar;

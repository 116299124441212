import { createActionType } from "../../_shared/functions/utils";

export const FETCH_CATEGORIES = createActionType(
  "FETCH_CATEGORIES",
  "category"
);
export const CREATE_THREAD = createActionType("CREATE_THREAD", "forum");
export const CREATE_COMMENT = createActionType("CREATE_COMMENT", "forum");
export const FETCH_THREAD = createActionType("FETCH_THREAD", "forum");
export const FETCH_THREAD_BY_TITLE = createActionType(
  "FETCH_THREAD_BY_TITLE",
  "forum"
);
export const LIKE_THREAD = createActionType("LIKE_THREAD", "forum");
export const LIKE_COMMENT = createActionType("LIKE_COMMENT", "forum");
export const UNLIKE_COMMENT = createActionType("UNLIKE_COMMENT", "forum");
export const UNLIKE_THREAD = createActionType("UNLIKE_THREAD", "forum");
export const SEARCH_THREAD = createActionType("SEARCH_THREAD", "forum");

export const fetchCategories = () => ({
  type: FETCH_CATEGORIES.START,
});

export const createThread = (payload) => ({
  type: CREATE_THREAD.START,
  payload,
});

export const createComment = (payload) => ({
  type: CREATE_COMMENT.START,
  payload,
});

export const fetchThread = (params) => ({
  type: FETCH_THREAD.START,
  params,
});

export const fetchThreadByTitle = (slug) => ({
  type: FETCH_THREAD_BY_TITLE.START,
  slug,
});

export const likeThread = (threadId) => ({
  type: LIKE_THREAD.START,
  threadId,
});

export const likeComment = (threadId) => ({
  type: LIKE_COMMENT.START,
  threadId,
});

export const unLikeComment = (threadId) => ({
  type: UNLIKE_COMMENT.START,
  threadId,
});

export const unLikeThread = (threadId) => ({
  type: UNLIKE_THREAD.START,
  threadId,
});

export const searchThread = (params) => ({
  type: SEARCH_THREAD.START,
  params,
});

import React from "react";
import { forEach } from "lodash";
import { truncate, formatCount } from "../../../../_shared/functions/utils";

const ThreadCard = ({ data, navigateTo }) => {
  const {
    title,
    content,
    created_by,
    comment_count,
    likes,
    views,
    title_link,
  } = data;

  const extractDescription = (HTMLString) => {
    let desc = "";

    let p = document.createElement("p");
    p.innerHTML = HTMLString;

    forEach(p.children, (itm) => {
      if (itm instanceof HTMLParagraphElement) desc = desc + itm.innerText;
    });

    return desc;
  };

  const handleRedirect = () => {
    navigateTo(`/discussion/${title_link}`);
  };

  return (
    <div className="thread-card">
      <h4 onClick={handleRedirect} className="md-title">
        {title}
      </h4>
      <p>{truncate(extractDescription(content), 170)}</p>
      <div className="user-activity">
        <div className="user-activity__details">
          <div className="user-activity__details__avatar"></div>
          <div className="user-activity__details__user-name">
            Posted by{" "}
            <span>{created_by.name ? created_by.name : "anonymous"}</span>
          </div>
        </div>
        <div className="user-activity__activities">
          <ul className="user-activity__activities__counts">
            <li className="user-activity__activities__counts__item">
              <i className="fas fa-heart" />
              {formatCount(likes)}
            </li>
            <li className="user-activity__activities__counts__item">
              <i className="fas fa-eye" />
              {formatCount(views)}
            </li>
            <li className="user-activity__activities__counts__item">
              <i className="fas fa-comment-dots" />
              {formatCount(comment_count)}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ThreadCard;

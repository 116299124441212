import { omit, map } from "lodash";
import {
  FETCH_CATEGORIES,
  CREATE_THREAD,
  FETCH_THREAD,
  FETCH_THREAD_BY_TITLE,
  LIKE_THREAD,
  UNLIKE_THREAD,
  LIKE_COMMENT,
  UNLIKE_COMMENT,
  SEARCH_THREAD,
} from "../actions";

const defaultState = {
  categoryList: [],
  threadList: {},
  currentThread: {},
};

const forumReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_CATEGORIES.SUCCESS:
      return Object.assign({}, state, { categoryList: payload });
    case CREATE_THREAD.SUCCESS:
      return Object.assign({}, state, {
        threadList: { data: [payload, ...state.threadList.data] },
      });
    case SEARCH_THREAD.SUCCESS:
    case FETCH_THREAD.SUCCESS:
      return Object.assign({}, state, {
        threadList: { data: payload.data, pagination: omit(payload, ["data"]) },
      });
    case LIKE_THREAD.SUCCESS:
      return Object.assign({}, state, {
        currentThread: {
          ...state.currentThread,
          isliked: true,
          likes: state.currentThread.likes + 1,
        },
      });
    case UNLIKE_THREAD.SUCCESS:
      return Object.assign({}, state, {
        currentThread: {
          ...state.currentThread,
          isliked: false,
          likes: state.currentThread.likes - 1,
        },
      });
    case LIKE_COMMENT.SUCCESS:
      const updateCommentLike = () => {
        const comments = map(state.currentThread.comments.data, (itm) => {
          if (itm._id === payload.threadId) {
            itm.likes = itm.likes + 1;
            itm.isliked = true;
          }

          return itm;
        });

        return comments;
      };

      return Object.assign({}, state, {
        currentThread: {
          ...state.currentThread,
          comments: {
            ...state.currentThread.comments,
            data: updateCommentLike(),
          },
        },
      });
    case UNLIKE_COMMENT.SUCCESS:
      const updateCommentUnLike = () => {
        const comments = map(state.currentThread.comments.data, (itm) => {
          if (itm._id === payload.threadId) {
            itm.likes = itm.likes - 1;
            itm.isliked = false;
          }

          return itm;
        });

        return comments;
      };

      return Object.assign({}, state, {
        currentThread: {
          ...state.currentThread,
          comments: {
            ...state.currentThread.comments,
            data: updateCommentUnLike(),
          },
        },
      });
    case FETCH_THREAD_BY_TITLE.SUCCESS:
      return Object.assign({}, state, {
        currentThread: payload,
      });
    default:
      return state;
  }
};

export default forumReducer;

import React, { Fragment } from "react";
import CreateThreadForm from "./CreateThreadForm";

const CreateThread = () => {
  return (
    <Fragment>
      <h5 className="md-title mb-5">New Discussion</h5>
      <CreateThreadForm />
    </Fragment>
  );
};

export default CreateThread;

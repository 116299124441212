import {
  createActionType,
  createActionString,
} from "../../_shared/functions/utils";

// Users
export const FETCH_CLIENT_ID = createActionType("FETCH_CLIENT_ID", "auth");
export const SOCIAL = createActionType("SOCIAL", "auth");
export const LOGIN = createActionType("LOGIN", "auth");
export const CLEAR_AUTH_STATE = createActionString("CLEAR_AUTH_STATE", "auth");

export const fetchClientId = () => ({
  type: FETCH_CLIENT_ID.START,
});

export const login = (payload, nextStep) => ({
  type: LOGIN.START,
  meta: { payload, nextStep },
});

export const social = (payload, nextStep, key) => ({
  type: SOCIAL.START,
  meta: { payload, nextStep, key },
});

export const clearAuthState = () => ({
  type: CLEAR_AUTH_STATE,
});

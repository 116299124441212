import React from "react";
import CustomButton from "../../../_shared/components/Button";
import "./Header.scss";
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <header className="header">
      <Link to="/">
        <img src="/images/icons/logo_opn.svg" alt="" height="40px" />
      </Link>
      <a href="https://www.opnplatform.com">
        <CustomButton
          name="back to opn webpage"
          btnType="no-border"
          classes=""
        />
      </a>
    </header>
  );
};

export default Header;

import {
  UI_ERROR,
  UI_LOADING,
  UI_MODAL_CUSTOM_TOGGLE,
  UI_MODAL_TOGGLE,
} from "../actions";
import get from "lodash/get";

const defaultState = {
  route: get(window.location, "pathname"),
  errors: {},
  loading: {},

  modal: {
    isOpen: false,
    isOpenCustom: false,
  },
};
const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UI_LOADING.START:
      return getNewLoadingState(state, action, true);
    case UI_LOADING.END:
      return getNewLoadingState(state, action, false);
    case UI_ERROR:
      return Object.assign({}, state, {
        errors: { ...state.errors, [action.key]: action.value },
      });
    case UI_MODAL_TOGGLE:
      return Object.assign({}, state, {
        modal: {
          isOpen: !state.modal.isOpen,
          isOpenCustom: false,
        },
      });
    case UI_MODAL_CUSTOM_TOGGLE:
      return Object.assign({}, state, {
        modal: {
          isOpen: false,
          isOpenCustom: !state.modal.isOpenCustom,
        },
      });
    default:
      return state;
  }
};

export default uiReducer;

function getNewLoadingState(currentState = {}, action, value) {
  const { key } = action;
  return Object.assign({}, currentState, {
    loading: { ...currentState.loading, [key]: value },
  });
}

import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Row, Col } from "reactstrap";
import CommentForm from "../CommentForm";
import ViewCard from "../ViewCard";

const propTypes = {
  threadId: PropTypes.string,
};

const Replies = (props) => {
  const { threadId, comments, likeComment, unLikeComment } = props;
  const { data } = comments;
  return (
    <Row>
      <Col md={12}>
        <CommentForm threadId={threadId} />
      </Col>
      <Col md={12}>
        {map(data, (comment) => {
          return (
            <ViewCard
              key={comment._id}
              data={comment}
              likeThread={likeComment}
              unLikeThread={unLikeComment}
            />
          );
        })}
      </Col>
    </Row>
  );
};

Replies.propTypes = propTypes;

export default Replies;

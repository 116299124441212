import React, { Fragment } from "react";
import PropTypes from "prop-types";
import timeAgo from "time-ago";
import ReactHtmlParser from "react-html-parser";
import { formatCount } from "../../../../_shared/functions/utils";

const propTypes = {
  _id: PropTypes.string,
  content: PropTypes.string,
  comment: PropTypes.string,
  created_by: PropTypes.object,
  likes: PropTypes.number,
  comment_count: PropTypes.number,
  views: PropTypes.number,
  created_at: PropTypes.string,
  isliked: PropTypes.bool,
};

const ViewCard = ({ data, likeThread, unLikeThread }) => {
  const {
    _id,
    content,
    comment,
    created_by,
    comment_count,
    likes,
    views,
    isliked: isLiked,
    created_at,
  } = data;

  return (
    <div className="view-card">
      <div className="view-card__content">
        <div>
          <div className="view-card__content__avatar"></div>
        </div>
        <div className="view-card__content__body">
          <h5 className="view-card__content__body__username">
            {created_by && created_by.name ? created_by.name : "anonymous"}
          </h5>
          <div className="view-card__content__body__timestamp">
            {timeAgo.ago(created_at)}
          </div>
          <div className="view-card__content__body__content">
            {ReactHtmlParser(content || comment)}
          </div>
        </div>
      </div>
      <div className="view-card__activities">
        <ul className="view-card__activities__counts">
          <li
            className="view-card__activities__counts__item"
            onClick={() => (isLiked ? unLikeThread(_id) : likeThread(_id))}
          >
            <i className={`fas fa-heart ${isLiked ? "liked" : ""}`} />
            {formatCount(likes)}
          </li>
          {!comment && (
            <Fragment>
              <li className="view-card__activities__counts__item">
                <i className="fas fa-eye" />
                {formatCount(views)}
              </li>
              <li className="view-card__activities__counts__item">
                <i className="fas fa-comment-dots" />
                {formatCount(comment_count)}
              </li>
            </Fragment>
          )}
        </ul>
      </div>
      {!comment && (
        <div className="view-card__replies">
          {formatCount(comment_count)} Replies
        </div>
      )}
    </div>
  );
};

ViewCard.propTypes = propTypes;

export default ViewCard;

import axios from "axios";
import AuthService from "./auth";

// Default config options
let isDev =
  /devforum.opnplatform/.test(window.location.host) ||
  /localhost/.test(window.location.host);
export const apiBase = isDev
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL;

const defaultOptions = {
  baseURL: apiBase,
  headers: {},
};

// Update instance
const instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(
  (config) => {
    const access_token = AuthService.getUserToken();
    const clientId = AuthService.getClientId();

    config.headers["client-id"] = clientId;
    config.headers["access-token"] = access_token;

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error.response);
  }
);
export default instance;

export const createAPIRequest = (config) => instance(config);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// CKEditor
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import AutoFormat from "@ckeditor/ckeditor5-autoformat/src/autoformat";

// axios
import { apiBase } from "../../../services/axios";

const propTypes = {
  change: PropTypes.func,
  clientId: PropTypes.string,
  token: PropTypes.string,
};

const Editor = (props) => {
  const { change, clientId, token } = props;
  const editorConfiguration = {
    plugins: [
      Essentials,
      Bold,
      Italic,
      Paragraph,
      Heading,
      Image,
      List,
      AutoFormat,
      ImageCaption,
      ImageToolbar,
      ImageStyle,
      ImageResize,
      ImageUpload,
      SimpleUploadAdapter,
      LinkPlugin,
      Indent,
      IndentBlock,
    ],
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "imageUpload",
      "|",
      "outdent",
      "indent",
    ],
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:alignLeft",
        "imageStyle:full",
        "imageStyle:alignRight",
      ],
      styles: [
        // This option is equal to a situation where no style is applied.
        "full",

        // This represents an image aligned to the left.
        "alignLeft",

        // This represents an image aligned to the right.
        "alignRight",
      ],
    },
    simpleUpload: {
      uploadUrl: `${apiBase}/file/forum/img`,
      headers: {
        "client-id": clientId,
        "access-token": token,
      },
    },
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfiguration}
      id="editor"
      onChange={(event, editor) => {
        const data = editor.getData();
        change("content", data);
      }}
    />
  );
};

Editor.propTypes = propTypes;

const mapStateToProps = (state) => ({
  clientId: state.auth.clientId,
  token: state.auth.accessToken && state.auth.accessToken.token,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import Login from "../../../containers/Login";
import Classnames from "classnames";
import CreateThread from "../../../containers/Forum/CreateThread";
import AuthService from "../../../services/auth";
import "./Modal.scss";

const propTypes = {
  showModal: PropTypes.bool,
  toggle: PropTypes.func,
};

const CustomModal = ({ showModal, toggle }) => {
  const isLoggedIn = AuthService.isLoggedIn();

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      className={Classnames("d-flex", "align-items-center", {
        "modal-lg": isLoggedIn,
      })}
    >
      <ModalBody>{!isLoggedIn ? <Login /> : <CreateThread />}</ModalBody>
    </Modal>
  );
};

CustomModal.propTypes = propTypes;

export default CustomModal;

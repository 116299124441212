import {
  FETCH_CLIENT_ID,
  apiRequest,
  GET,
  POST,
  LOGIN,
  SOCIAL,
  uiToggleModal,
} from "../actions";
import { API } from "../../_shared/defs";

const fetchClientId = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_CLIENT_ID.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.CLIENT}/id`,
        key: "fetchClientId",
        onSuccess: FETCH_CLIENT_ID.SUCCESS,
        noSuccessToast: true,
      })
    );
  }
};

const social = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === SOCIAL.START) {
    const { payload, key } = action.meta;
    let apiUrl;

    if (key === "googleAuth") apiUrl = `${API.AUTH}/oauth/callback`;
    if (key === "lookup") apiUrl = `${API.AUTH}/lookup`;
    dispatch(
      apiRequest({
        method: POST,
        url: apiUrl,
        key,
        successMessage: "Login successful",
        onSuccess: (data) => {
          dispatch({
            type: SOCIAL.SUCCESS,
            payload: data,
          });

          dispatch(uiToggleModal());
        },
        payload,
      })
    );
  }
};

const login = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === LOGIN.START) {
    const { payload } = action.meta;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}/login`,
        key: "login",
        successMessage: "Login successful",
        onSuccess: (data) => {
          dispatch({
            type: LOGIN.SUCCESS,
            payload: data,
          });

          dispatch(uiToggleModal());
        },
        payload,
      })
    );
  }
};

export default [social, fetchClientId, login];

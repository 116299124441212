import _, { omit } from "lodash";
import {
  LOGIN,
  SOCIAL,
  FETCH_CLIENT_ID,
  CLEAR_AUTH_STATE,
} from "../actions";

const defaultState = {
  clientId: undefined,
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  successfulReset: false,
  isLoggedIn: false,
};

const authReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_CLIENT_ID.SUCCESS:
      return Object.assign({}, state, { clientId: payload.clientId });
    case SOCIAL.SUCCESS:
    case LOGIN.SUCCESS:
      return Object.assign({}, state, {
        accessToken: {
          token: payload.access_token.token,
          exp: payload.access_token.expires,
        },
        refreshToken: {
          token: payload.refresh_token.token,
          exp: payload.refresh_token.expires,
        },
        user: _.omit(payload, ["refresh_token", "access_token"]),
        isLoggedIn: true,
      });
    case CLEAR_AUTH_STATE:
      return Object.assign({}, state, omit(defaultState, ["clientId"]));
    default:
      return state;
  }
};

export default authReducer;

import store from "../redux/store";
import moment from "moment";

class AuthService {
  constructor() {
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.getUserToken = this.getUserToken.bind(this);
    this.getUserTokenExpDate = this.getUserTokenExpDate.bind(this);
    this.getClientId = this.getClientId.bind(this);
    this.clearUserData = this.clearUserData.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }

  getUserToken() {
    const { getState } = store;
    const { auth } = getState();
    return auth.accessToken && auth.accessToken.token;
  }

  getCompanyId() {
    const { getState } = store;
    const { auth } = getState();
    return auth.user.companies[0]._id;
  }

  getUserTokenExpDate() {
    const { getState } = store;
    const { auth } = getState();
    return auth.accessToken && auth.accessToken.exp;
  }

  getClientId() {
    const { getState } = store;
    const { auth } = getState();
    return auth.clientId;
  }

  isLoggedIn() {
    const token = this.getUserToken();
    const exp = this.getUserTokenExpDate();

    if (token) {
      const sessionTimeExp = exp;
      const start = moment().valueOf();
      const end = moment(sessionTimeExp).valueOf();
      const timeDiff = end > start;

      return timeDiff;
    }

    return false;
  }

  clearUserData() {
    localStorage.removeItem("opn:persist");
  }

  logoutUser() {
    this.clearUserData();
  }
}

export default new AuthService();

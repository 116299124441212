import {
  FETCH_CATEGORIES,
  CREATE_THREAD,
  CREATE_COMMENT,
  FETCH_THREAD,
  FETCH_THREAD_BY_TITLE,
  LIKE_THREAD,
  LIKE_COMMENT,
  UNLIKE_THREAD,
  UNLIKE_COMMENT,
  SEARCH_THREAD,
  apiRequest,
  POST,
  GET,
  PUT,
  uiToggleModal,
} from "../actions";
import { API } from "../../_shared/defs";
import AuthService from "../../services/auth";

const fetchCategories = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_CATEGORIES.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.CATEGORY}/list/all`,
        key: "fetchCategories",
        onSuccess: FETCH_CATEGORIES.SUCCESS,
      })
    );
  }
};

const createThread = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_THREAD.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.FORUM}/thread`,
        key: "createThread",
        onSuccess: (data) => {
          dispatch({
            type: CREATE_THREAD.SUCCESS,
            payload: data,
          });

          dispatch(uiToggleModal());
        },
        payload,
      })
    );
  }
};

const createComment = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_COMMENT.START) {
    const isLoggedIn = AuthService.isLoggedIn();
    const { payload } = action;

    !isLoggedIn
      ? dispatch(uiToggleModal())
      : dispatch(
          apiRequest({
            method: POST,
            url: `${API.FORUM}/comment`,
            key: "likeThread",
            onSuccess: CREATE_COMMENT.SUCCESS,
            payload,
          })
        );
  }
};

const fetchThread = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_THREAD.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.FORUM}/thread/all`,
        key: "fetchThread",
        onSuccess: FETCH_THREAD.SUCCESS,
        params,
      })
    );
  }
};

const fetchThreadByTitle = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_THREAD_BY_TITLE.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.FORUM}/thread/title/${action.slug}`,
        key: "fetchThreadByTitle",
        onSuccess: FETCH_THREAD_BY_TITLE.SUCCESS,
      })
    );
  }
};

const likeThread = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === LIKE_THREAD.START) {
    const isLoggedIn = AuthService.isLoggedIn();

    !isLoggedIn
      ? dispatch(uiToggleModal())
      : dispatch(
          apiRequest({
            method: PUT,
            url: `${API.FORUM}/thread/like/${action.threadId}`,
            key: "likeThread",
            onSuccess: LIKE_THREAD.SUCCESS,
          })
        );
  }
};

const likeComment = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === LIKE_COMMENT.START) {
    const isLoggedIn = AuthService.isLoggedIn();
    const { threadId } = action;

    !isLoggedIn
      ? dispatch(uiToggleModal())
      : dispatch(
          apiRequest({
            method: PUT,
            url: `${API.FORUM}/comment/like/${threadId}`,
            key: "likeComment",
            onSuccess: (data) => {
              dispatch({
                type: LIKE_COMMENT.SUCCESS,
                payload: { ...data, threadId },
              });
            },
          })
        );
  }
};

const unLikeComment = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UNLIKE_COMMENT.START) {
    const isLoggedIn = AuthService.isLoggedIn();
    const { threadId } = action;

    !isLoggedIn
      ? dispatch(uiToggleModal())
      : dispatch(
          apiRequest({
            method: PUT,
            url: `${API.FORUM}/comment/unlike/${threadId}`,
            key: "unLikeComment",
            onSuccess: (data) => {
              dispatch({
                type: UNLIKE_COMMENT.SUCCESS,
                payload: { ...data, threadId },
              });
            },
          })
        );
  }
};

const unLikeThread = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UNLIKE_THREAD.START) {
    const isLoggedIn = AuthService.isLoggedIn();

    !isLoggedIn
      ? dispatch(uiToggleModal())
      : dispatch(
          apiRequest({
            method: PUT,
            url: `${API.FORUM}/thread/unlike/${action.threadId}`,
            key: "unLikeThread",
            onSuccess: UNLIKE_THREAD.SUCCESS,
          })
        );
  }
};

const searchThread = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === SEARCH_THREAD.START) {
    const { params } = action;

    dispatch(
      apiRequest({
        method: GET,
        url: `${API.FORUM}/thread/search`,
        key: "searchThread",
        onSuccess: SEARCH_THREAD.SUCCESS,
        params,
      })
    );
  }
};

export default [
  fetchCategories,
  createThread,
  createComment,
  fetchThread,
  fetchThreadByTitle,
  likeThread,
  likeComment,
  unLikeThread,
  unLikeComment,
  searchThread,
];

import React from "react";
import LoginForm from "./LoginForm";
import SocialAuthForm from "./SocialAuthForm";
import "./Login.scss";

const Login = () => {
  return (
    <div className="login">
      <h5 className="md-title mb-5">Welcome to OPN</h5>
      <LoginForm />
      <div className="divider-both mt-5">OR</div>
      <SocialAuthForm />
    </div>
  );
};

export default Login;

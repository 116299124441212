import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Button from "../../../_shared/components/Button";
import ProgressBar from "../../../_shared/components/ProgressBar";
import {
  fetchThreadByTitle,
  likeThread,
  unLikeThread,
  likeComment,
  unLikeComment,
} from "../../../redux/actions";
import "./ThreadView.scss";
import ViewCard from "./ViewCard";
import Replies from "./Replies";

const propTypes = {
  fetchThread: PropTypes.func,
};

const defaultProps = {
  isFetchingThreadByTitle: true,
};

const ThreadView = (props) => {
  const {
    fetchThreadByTitle,
    currentThread,
    isFetchingThreadByTitle,
    likeThread,
    unLikeThread,
    likeComment,
    unLikeComment,
  } = props;
  const { slug } = useParams();
  const { goBack } = useHistory();

  useEffect(() => {
    fetchThreadByTitle(slug);
  }, [fetchThreadByTitle, slug]);

  console.log("currentThread :", currentThread);

  return (
    <main className="thread-view">
      <Container>
        <Row>
          <Col md={12}>
            <Button
              onclick={goBack}
              name="discussions"
              faName="fa-chevron-left"
              btnType="no-border"
              classes="thread-view__back-btn"
            />
          </Col>
          <Col md={{ size: 10, offset: 1 }} className="mt-5">
            {isFetchingThreadByTitle ? (
              <div className="d-flex justify-content-center p-5">
                <ProgressBar />
              </div>
            ) : (
              <Fragment>
                <div className="title-tag">
                  <h1 className="lg-title">{currentThread.title}</h1>
                </div>
                <ViewCard
                  data={currentThread}
                  likeThread={likeThread}
                  unLikeThread={unLikeThread}
                />
                <Replies
                  threadId={currentThread._id}
                  comments={currentThread.comments}
                  likeComment={likeComment}
                  unLikeComment={unLikeComment}
                />
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

ThreadView.propTypes = propTypes;
ThreadView.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  currentThread: state.forum.currentThread,
  isFetchingThreadByTitle: state.ui.loading.fetchThreadByTitle,
});

const mapDispatchToProps = {
  fetchThreadByTitle,
  likeThread,
  unLikeThread,
  likeComment,
  unLikeComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreadView);

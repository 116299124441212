import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import ui from "./ui";
import auth from "./auth";
import forum from "./forum";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    ui,
    auth,
    forum,
  });

export default appReducer;

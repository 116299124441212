import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import CustomButton from "../../../_shared/components/Button";
import Search from "./Search";
import "./ThreadListing.scss";
import {
  fetchThread,
  fetchCategories,
  uiToggleModal,
  navigateTo,
  searchThread,
} from "../../../redux/actions";
import ThreadCard from "./ThreadCard";
import ProgressBar from "../../../_shared/components/ProgressBar";

const propTypes = {
  fetchThread: PropTypes.func,
  searchThread: PropTypes.func,
};

const defaultProps = {
  isFetchingThread: true,
};

const ThreadListing = (props) => {
  const {
    fetchThread,
    fetchCategories,
    uiToggleModal,
    threadList,
    isFetchingThread,
    navigateTo,
    searchThread,
  } = props;

  useEffect(() => {
    fetchCategories();

    const params = {
      offset: 0,
      count: 10,
    };

    fetchThread(params);
  }, [fetchCategories, fetchThread]);

  const renderThreadList = (data) =>
    data.map((thread, idx) => (
      <ThreadCard key={idx} data={thread} navigateTo={navigateTo} />
    ));

  return (
    <main className="forum">
      <header className="forum__header">
        <h1 className="xl-title">Welcome to the OPN Community</h1>
        <p>Share and learn in the OPN community.</p>
      </header>
      <Container>
        <Row className="search-section">
          <Col md={8} className="search-section__inner">
            <Search searchThread={searchThread} />
            <div className="divider"></div>
            <CustomButton onclick={uiToggleModal} name="start a discussion" />
          </Col>
        </Row>
        <Row className="list-section">
          <Col md={8} className="list-section__inner">
            {isFetchingThread ? (
              <div className="d-flex justify-content-center p-5">
                <ProgressBar />
              </div>
            ) : (
              renderThreadList(threadList)
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

ThreadListing.propTypes = propTypes;
ThreadListing.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  threadList: state.forum.threadList.data,
  isFetchingThread: state.ui.loading.fetchThread,
});

const mapDispatchToProps = {
  fetchThread,
  fetchCategories,
  uiToggleModal,
  navigateTo,
  searchThread,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreadListing);

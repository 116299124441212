import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "../../../api";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import "./SocialAuthForm.scss";
import { social } from "../../../redux/actions";

const propTypes = {
  nextStep: PropTypes.func,
  social: PropTypes.func,
};

const SocialAuthForm = (props) => {
  const { nextStep, social } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const { access_token, refresh_token, error } = useParams();

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (access_token && refresh_token) {
      const payload = { access_token };
      social(payload, nextStep, "lookup");
    }
  }, [error, access_token, refresh_token, social, nextStep]);

  const handleClick = (provider) => {
    setErrorMessage(null);
    auth
      .getAuth()
      .signInWithPopup(provider)
      .then((data) => {
        const payload = { auth: data };
        social(payload, nextStep, "googleAuth");
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  };

  const linkedInOAuth = () => {
    let isDev =
      /devforum.opnplatform/.test(window.location.host) ||
      /localhost/.test(window.location.host);
    const apiBase = isDev
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL;
    window.location.href = `${apiBase}/user/oauth/linkedin`;
  };

  return (
    <div>
      <div className="social-auth">
        <div
          className="social-auth__google"
          onClick={() => handleClick(auth.googleOAuth())}
        >
          <img src="/images/icons/google.svg" alt="Google logo" />
          Google
        </div>
        <div className="social-auth__linked-in" onClick={linkedInOAuth}>
          <img src="/images/icons/linked-in.svg" alt="LinkedIn logo" />
          LinkedIn
        </div>
      </div>
      {errorMessage ? (
        <p className="text-danger py-5">{errorMessage}</p>
      ) : (
        <Fragment />
      )}
    </div>
  );
};

SocialAuthForm.propTypes = propTypes;

const mapStateToProps = (state) => ({
  location: state.router.location,
});

const mapDispatchToProps = {
  social,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialAuthForm);

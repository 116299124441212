// react library
import React from "react";
import ReactDOM from "react-dom";

// third party library
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

// components
import App from "./App";

// Actions
import { fetchClientId } from "./redux/actions";

// serviceWorker
import * as serviceWorker from "./serviceWorker";

// store
import store, { history } from "./redux/store";

// styles
import "bootstrap/dist/css/bootstrap.css";
import "./styles/index.scss";

// Fetch Client Id if not available
const { auth } = store.getState();
if (!auth.clientId) store.dispatch(fetchClientId());

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import PropTypes from "prop-types";
import { change, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Form, FormGroup } from "reactstrap";
import Editor from "../../../../_shared/components/CKEditor";
import CustomButton from "../../../../_shared/components/Button";
import { createComment } from "../../../../redux/actions";

const propTypes = {
  change: PropTypes.func,
};

const CommentForm = (props) => {
  const {
    handleSubmit,
    change,
    isLoading,
    submitting,
    pristine,
    invalid,
    createComment,
    threadId,
  } = props;

  const handleOnSubmit = (values) => {
    const { content } = values;

    const payload = {
      comment: content,
      thread: threadId,
    };

    createComment(payload);
  };

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <Editor change={change} />
      <FormGroup className="text-right py-3">
        <CustomButton
          name="create"
          type="submit"
          loading={isLoading}
          disabled={invalid || isLoading || pristine || submitting}
        />
      </FormGroup>
    </Form>
  );
};

CommentForm.propTypes = propTypes;

const formName = "commentForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: validateFunc,
})(CommentForm);

const mapStateToProps = (state) => ({
  formData: getFormValues(formName)(state),
  isLoading: state.ui.loading.createComment,
  categoryList: state.forum.categoryList,
});

const mapDispatchToProps = { change, createComment };

// export default connectedForm;
export default connect(mapStateToProps, mapDispatchToProps)(connectedForm);

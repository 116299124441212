import React, { useEffect } from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CustomButton from "../../../../_shared/components/Button";
import { formatCategory } from "../../../../_shared/functions/utils";
import Editor from "../../../../_shared/components/CKEditor";

import { Col, Form, FormGroup, Row } from "reactstrap";
import {
  TextInputField,
  SelectInputField,
} from "../../../../_shared/components/ReduxFormFields/index";
import { fetchCategories, createThread } from "../../../../redux/actions";

// import validateFunc from "./loginValidation";
import "./CreateThreadForm.scss";

const propTypes = {
  isLoading: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
};

const CreateThreadForm = (props) => {
  const {
    handleSubmit,
    change,
    categoryList,
    isLoading,
    submitting,
    pristine,
    invalid,
    createThread,
  } = props;

  useEffect(() => {}, []);

  const handleOnSubmit = (values) => {
    const { title, content, tags } = values;
    const TagValues = tags.map((item) => item.value);

    const payload = {
      title,
      content,
      status: "PUBLISH",
      tags: TagValues,
    };

    createThread(payload);
  };

  return (
    <Form
      className="create-thread-form"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <Row>
        <Col md="12">
          <FormGroup>
            <Field
              name="title"
              label="Discussion Title"
              id="title"
              type="text"
              disabled={isLoading}
              component={TextInputField}
              placeholder="Discussion Title"
            />
          </FormGroup>
          <FormGroup>
            <Editor change={change} />
          </FormGroup>
          <FormGroup>
            <Field
              name="tags"
              label="Tags"
              type="text"
              id="tags"
              options={formatCategory(categoryList)}
              disabled={isLoading}
              component={SelectInputField}
              placeholder="Tags"
              isMulti
            />
          </FormGroup>
        </Col>
        <Col md="12" className="text-right">
          <CustomButton
            name="create"
            type="submit"
            loading={isLoading}
            disabled={invalid || isLoading || pristine || submitting}
          />
        </Col>
      </Row>
    </Form>
  );
};

CreateThreadForm.propTypes = propTypes;
CreateThreadForm.defaultProps = defaultProps;

const formName = "createThreadForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: validateFunc,
})(CreateThreadForm);

// export default connectedForm;
export default connect(
  (state) => ({
    formData: getFormValues(formName)(state),
    isLoading: state.ui.loading.login || state.ui.loading.createThread,
    categoryList: state.forum.categoryList,
  }),
  {
    change,
    fetchCategories,
    createThread,
  }
)(connectedForm);

import React, { Fragment } from "react";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import moment from "moment";
import { isArray } from "lodash";
import "./Utils.scss";

export const formatSuccessMessage = (message) => {
  return (
    <Fragment>
      {message && (
        <span>
          <img
            src={`${process.env.IMAGE_URL}/assets/images/svg/icons/check-mark-green.svg`}
            className="check-icon"
            alt=""
          />
          {message}
        </span>
      )}
    </Fragment>
  );
};

export const formatMessagesFromError = (error) => {
  let message = (
    <span>
      <i className="fas fa-exclamation-triangle" /> {error && error}
    </span>
  );

  if (error && !isArray(error)) return message;
  if (error && isArray(error))
    return (
      <div className="multi-error">
        <i className="fas fa-exclamation-triangle" />
        <ul className="pl-1">
          {error.map((item, i) => {
            return <li key={i}>{item.message}</li>;
          })}
        </ul>
      </div>
    );
};

export const createActionType = (type, entity = "App") => ({
  START: `@@[${entity}] ${type}`,
  SUCCESS: `@@[${entity}] ${type}_SUCCESS`,
  ERROR: `@@[${entity}] ${type}_ERROR`,
  END: `@@[${entity}] ${type}_END`,
});

export const createActionString = (type, entity = "App") =>
  `[${entity}] ${type}`;

export const truncate = (string, charLength) => {
  if (string.length > charLength) {
    return string.slice(0, charLength) + " ...";
  } else {
    return string;
  }
};

export const stringify = (string) => {
  return string.toString().toLowerCase().replace(/&/g, " and "); // Replace & with 'and'
  // .replace(/\s+/g, "-") // Replace spaces with -
  // .replace(/--+/g, "-") // Replace multiple - with single -
  // .replace(/^-+/, "") // Trim - from start of text
  // .replace(/-+$/, ""); // Trim - from end of text
};

export const priceFormat = (price, num) => {
  if (price !== null) {
    let number = numeral(price / 100);

    if (num) return `$ ${number.format("0.00")}`;
    let res = (
      <NumberFormat
        value={number.format("0.00")}
        displayType="text"
        thousandSeparator
        prefix="&#36;"
      />
    );
    return res;
  } else {
    return "---";
  }
};

export const dateFormat = (date) => moment(date).format("D MMM YYYY");

export const formatCount = (number) => {
  const COUNT_FORMAT = number < 1100 ? "0a" : "0.0a";

  return numeral(number).format(COUNT_FORMAT);
};

export const formatCategory = (categories) => {
  let data = [];
  categories.forEach((category) => {
    const item = {
      label: <p>{stringify(category.name)}</p>,
      value: category.name,
    };
    
    data = [...data, item];
  });

  return data;
};

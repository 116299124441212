// react library
import React from "react";
import { connect } from "react-redux";

// third party library
import { Switch, Route } from "react-router-dom";

// components
import { ThreadView, ThreadListing } from "./containers/Forum";
import CustomModal from "./_shared/components/Modal";
import Header from "./_shared/components/Header";
import Footer from "./_shared/components/Footer";

// actions
import { uiToggleModal } from "./redux/actions";

/**
 * @desc handles routing
 @returns {object} routes
 */
function App({ showModal, uiToggleModal }) {
  return (
    <main className="view">
      <CustomModal showModal={showModal} toggle={uiToggleModal} />
      <Header />
      <section className="content">
        <Switch>
          <Route exact path="/" component={ThreadListing} />
          <Route exact path="/discussion/:slug" component={ThreadView} />
          {/* <Route component={NotFound} /> */}
        </Switch>
      </section>
      <Footer />
    </main>
  );
}

const mapStateToProps = (state) => ({
  showModal: state.ui.modal.isOpen,
});

const mapDispatchToProps = {
  uiToggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
